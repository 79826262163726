function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    survey: ({
      surveyId,
      managerId,
      peerId,
      surveyResultId,
      campaignId,
    }: {
      surveyId: string;
      managerId?: string;
      peerId?: string;
      surveyResultId?: string;
      campaignId?: string;
    }) => {
      const generatedPath = surveyResultId
        ? `/reviews/wizard/${surveyId}/${peerId ? "peer" : "manager"}/${
            peerId || managerId
          }/${surveyResultId}`
        : peerId
        ? `/reviews/wizard/${surveyId}/peer/${peerId}/campaignId/${campaignId}/`
        : `/reviews/wizard/${surveyId}/manager/${managerId}/campaignId/${campaignId}/`;

      // Log the generated path to see what is being returned
      // console.log("PATH: Generated Survey Path:", generatedPath);

      return path(ROOTS_DASHBOARD, generatedPath);
    },

    surveySummary: (id: string) =>
      path(ROOTS_DASHBOARD, `/reviews/summary/${id}`),
    surveyResults: (id: string) => path(ROOTS_DASHBOARD, `/reviews/${id}`),
    reviews: path(ROOTS_DASHBOARD, "/reviews"),

    summary: path(ROOTS_DASHBOARD, "/profile"),
    detailedReport: (campaignId: string) =>
      path(ROOTS_DASHBOARD, `/profile/detailedReport/${campaignId}`),
    aidetailedReport: (campaignId: string) =>
      path(ROOTS_DASHBOARD, `/profile/aidetailedReport/${campaignId}`),
    companyStats: path(ROOTS_DASHBOARD, "/company-stats"),

    goals: path(ROOTS_DASHBOARD, "/goals"),
    goalsBuilder: path(ROOTS_DASHBOARD, "/goals/builder"),
    goalsTracker: (id: string) => path(ROOTS_DASHBOARD, `/goals/track/${id}`),

    discussion: (id: string) => path(ROOTS_DASHBOARD, `/discussions/${id}`),
    starLeaderValues: path(ROOTS_DASHBOARD, "/starLeaderValues"),
    starLeaderCookiePolicy: path(ROOTS_DASHBOARD, "/starLeaderCookiePolicy"),
    starLeaderPrivacyPolicy: path(ROOTS_DASHBOARD, "/starLeaderPrivacyPolicy"),
    starLeaderLegalNotice: path(ROOTS_DASHBOARD, "/starLeaderLegalNotice"),
    starLeaderCommitments: path(ROOTS_DASHBOARD, "/starLeaderCommitments"),
    surveyLandingPage: path(ROOTS_DASHBOARD, "/surveyLandingPage"),

    campaigns: path(ROOTS_DASHBOARD, "/campaigns"),
    createCampaign: path(ROOTS_DASHBOARD, "/campaigns/new"),

    admin: path(ROOTS_DASHBOARD, "/admin"),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_DOCS = {
  root: "https://docs.minimals.cc",
  changelog: "https://docs.minimals.cc/changelog",
};

export const PATH_ZONE_ON_STORE =
  "https://mui.com/store/items/zone-landing-page/";

export const PATH_MINIMAL_ON_STORE =
  "https://mui.com/store/items/minimal-dashboard/";

export const PATH_FREE_VERSION =
  "https://mui.com/store/items/minimal-dashboard-free/";

export const PATH_FIGMA_PREVIEW =
  "https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1";
